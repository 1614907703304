import React from "react";

import SegChart from "components/Chart/SegChart";

function GridChart({ accReports }) {
  return (
    <div
      id="grid-chart"
      className="grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-6 gap-4"
    >
      {Object.values(accReports).map((report, idx) => (
        <SegChart key={idx} report={report} />
      ))}
    </div>
  );
}

export default GridChart;
