import React from "react";

import ChartCard from "components/Chart/ChartCard";

function InfoChart({ data, name, color, getReport }) {
  let value = data.value;
  if (value && !isNaN(data.value)) {
    value = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
  }

  let legend = null;
  if (data.args && data.args.legend) {
    legend = (
      <p className="text-lg md:text-2xl font-bold text-lime-600 pl-6">
        {data.args.legend}
      </p>
    );
  }
  return (
    <ChartCard
      header={name}
      header_meta={data.header_meta}
      desc={data.desc}
      desc_meta={data.desc_meta}
      color={color}
      getReport={getReport}
    >
      <div className="flex justify-center">
        <p className="text-5xl sm:text-5xl md:text-5xl lg:text-5xl text-center text-slate-600 font-bold py-4">
          {value}
        </p>
        {legend}
      </div>
    </ChartCard>
  );
}

export default InfoChart;
