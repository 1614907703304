import React, { Fragment, useEffect, useState } from "react";
import proxy from "api/proxy";
import func from "../../tools/functions";
import CharField from "components/MeForm/CharField";
import { signal } from "@preact/signals-react";
import imgDefault from "../../assets/img/default-image.jpg";
import TextField from "components/MeForm/TextField";
import StdButton from "components/Buttons/StdButton";
import { useWizardStore } from "store/wizardStore";
import { Alert } from "ext-apps/WebBookingHotel/Components/Alert";
import { checkRequiredForm } from "tools/form";
import SectionNoData from "components/Tools/SectionNoData";
import TimeLine from "apps/SaleCallCenter/TimeLine";
import TakeCapture from "components/MeForm/TakeCapture";

const ATTRS = {
  level: "wizard",
};

const fields = {
  sale_number: {
    name: "sale_number",
    label: "sale.gift.search_order",
    type: "char",
    placeholder: "VEN0001",
  },
  photo: {
    name: "photo",
    // label: "sale.gift.photo",
    type: "char",
  },
};

const storeRequired = ["sale_number"];

const sale_number = signal(null);
const photo = signal(null);
const alertN = signal(null);

const ConfirmDelivery = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const number = params.get("number");
  const [data, setData] = useState(null);
  const { record, store, reset: resetWizard } = useWizardStore();

  const getInvoice = async () => {
    const dom = [
      ["number", "=", number || sale_number.value],
      [["number", "!=", ""]],
    ];
    const fields = [
      "id",
      "shipment_date",
      "delivery_time",
      "total_amount",
      "lines",
      "lines.id",
      "lines.gift_of",
      "lines.gift_for",
      "lines.address_gift",
      "lines.receive",
      "lines.unit_price_w_tax",
      "lines.quantity",
      "lines.product.name",
      "lines.product.list_price",
      "lines.product.sale_price_w_tax",
      "lines.product.images.image_url",
      "lines.product.image_url",
      "web_shop",
      "consumer.name",
      "consumer.address",
      "consumer.phone",
    ];

    const { data, error } = await proxy.search("sale.sale", dom, fields, 1);
    if (data) {
      setData(data[0]);
    } else {
      setData(null);
    }
  };
  // useEffect(() => {
  //   getInvoice();
  // }, [number]);

  const changedStatusDelivered = async () => {
    const sale = {
      sale: data.id,
    };
    const args = {
      model: "sale.sale",
      method: "confirm_delivery",
      args: [{ sales: [data.id] }],
    };
    const { data: resCharge, error } = await proxy.methodCall(args);

    if (resCharge == null) {
      alertN.value = "Pedido entregado exitosamente!";
    } else {
      alertN.value = "Ocurrio un error al momento de crear el cargo";
    }
  };

  const ItemProduct = () => {
    return (
      <div className="flex w-full items-start">
        {/* <TimeLine /> */}
        <div className="space-y-4 p-4 w-3/4">
          <h2 className="text-2xl">Datos del pedido</h2>
          <div className="flex space-x-6">
            <span>
              <b>Fecha de entrega:</b> {data.shipment_date}
            </span>
            <span>
              <b>Hora de entrega:</b> {data.delivery_time}
            </span>
          </div>
          {data?.["consumer."] ? (
            <div className="flex space-x-6">
              <span>
                <b>Para:</b> {data?.["consumer."].name}
              </span>
              <span>
                <b>Telefono:</b> {data?.["consumer."].phone}
              </span>
              <span>
                <b>Direccion:</b> {data?.["consumer."].address}
              </span>
            </div>
          ) : (
            <div className="flex space-x-6">
              <span>
                <b>Para:</b> {data?.["lines."][0].receive}
              </span>
              <span>
                <b>Direccion:</b> {data?.["lines."][0].address_gift}
              </span>
            </div>
          )}
          {data &&
            data["lines."].map((item, key) => {
              return (
                <div
                  className="bg-white p-4 flex space-x-4 items-center rounded-md shadow-sm"
                  key={key}
                >
                  <img
                    src={
                      item["product."]["images."][0]
                        ? item["product."]["images."][0].image_url
                        : imgDefault
                    }
                    className="h-20 w-20 rounded-full  object-cover"
                  />

                  <div className="flex flex-col space-y-1">
                    <span className="font-semibold">
                      {item["product."].name}
                    </span>
                    <span>
                      {item.unit_price_w_tax} x {item.quantity}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mt-8 w-1/4 font-medium text-2xl text-center bg-gray-200 py-10 shadow-sm rounded-md px-5">
          {/* <TakeCapture field={fields.photo} attrs={ATTRS} data={photo} /> */}
          <div className="">
            Total: {data?.total_amount}
            <StdButton
              style="mx-auto text-base px-7"
              size={" mt-4"}
              color="yellowPresik"
              onClick={() => changedStatusDelivered()}
            >
              CONFIRMAR ENTREGA
            </StdButton>
          </div>
        </div>
      </div>
    );
  };

  // if (!data) {
  //   return (
  //     <div className="p-10">
  //       <SectionNoData text="No existen ventas con este número" />;
  //     </div>
  //   );
  // }
  return (
    <Fragment>
      <header className="w-full bg-gray-100 pb-5">
        <div className="container mx-auto ">
          <img
            src="https://www.floristeriabukaflor.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fdl4sdiukx%2Fimage%2Fupload%2Fv1706732572%2Fh1miumkd2j2isqi26epj.png&w=1920&q=75"
            className="max-w-[200px]"
          />
        </div>
      </header>
      <div className=" min-h-full  container mx-auto mt-10 bg-gray-100 rounded-md p-6 max-w-7xl">
        <div className="relative">
          <h1 className="text-3xl text-center">
            Confirmacion de entrega de orden <b>{number}</b>
          </h1>
          <div className="flex justify-center  space-x-3 relative items-end w-full max-w-[500px] mx-auto">
            <CharField
              field={fields.sale_number}
              attrs={ATTRS}
              data={sale_number}
              placeholder="VEN00001"
            />
            <StdButton
              height="h-10"
              style="relative -top-2"
              onClick={() => getInvoice()}
              disabled={!sale_number.value ? true : false}
            >
              Ver Orden
            </StdButton>
          </div>
          {data && <ItemProduct />}
          {alertN.value && (
            <Alert
              text={alertN.value}
              show={alertN ? true : false}
              onClose={() => (alertN.value = null)}
              color="bg-blue-presik text-white"
            />
          )}
        </div>
      </div>

      <div className="flex justify-center w-full"></div>
    </Fragment>
  );
};

export default ConfirmDelivery;
