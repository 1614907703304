import React, { useState, useEffect } from "react";

import HotelCalendar from "./Calendar";
import proxy from "api/proxy";
import colors from "theme/colors";
import helper from "./calendar/helper";
import store from "store";

const model = "hotel.room";

function Rack() {
  const [rooms, setRooms] = useState([]);
  const session = store.get("ctxSession");

  function orderedRooms(data) {
    const groupRooms = new Map();
    for (const room of data) {
      const products = room["main_accommodation."]["products"];
      const accoId = products[0];
      const { name, id: roomId, type_: categoryId } = room;
      if (!groupRooms.get(accoId)) {
        const dataAcco = {
          rooms: new Map(),
          accoId: accoId,
          accoName: room["main_accommodation."].name,
        };
        groupRooms.set(accoId, dataAcco);
      }
      let group = groupRooms.get(accoId);
      const categoryName = room["type_."].name;
      group.rooms.set(roomId, {
        id: roomId,
        name: name,
        categoryId: categoryId,
        categoryName: categoryName,
        categoryColor: colors.colorsBg[categoryId],
        // status: "clean",
      });
    }
    return groupRooms;
  }

  // async function getOwnerUser() {
  //   const model = "hotel.room-res.user";
  //   const dom = [["user", "=", session.user]];
  //   const fields_names = ["id"];
  //   const { data } = await proxy.search(model, dom, fields_names, 10);
  //   if (data.length > 0) {
  //     return true;
  //   }
  // }

  async function getRooms() {
    const fields_names = [
      "name",
      "type_",
      "type_.name",
      "main_accommodation",
      "main_accommodation.name",
      "main_accommodation.products",
    ];
    const order = [["code", "ASC"]];
    const dom = [["active", "=", true]];
    // ONLY FOR TESTING PURPOSES
    const rolOwner = await helper.getOwnerUser();
    console.log("rolOwner .? ??", rolOwner);
    if (rolOwner) {
      store.set("owner", true);
      dom.push(["authorized_users", "in", [session.user]]);
    }
    const { data } = await proxy.search(model, dom, fields_names, 500, order);
    const _rooms = orderedRooms(data);
    setRooms(_rooms);
  }

  useEffect(() => {
    getRooms();
  }, []);

  return <HotelCalendar rooms={rooms} />;
}

export default Rack;
