import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";

import Button from "components/Buttons/StdButton";
import CardGroupCharts from "components/Chart/CardGroupCharts";
import GridChart from "components/Chart/GridChart";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import proxy from "api/proxy";

const FIELDS = [
  "sequence",
  "color",
  "category",
  "report.name",
  "report.model",
  "report.method",
  "report.type",
  "report.comment",
  "report.colspan",
  "report.in_thousands",
];

function DashChart(props) {
  const [reports, setReports] = useState(null);
  const [view, setView] = useState("dash");
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState("dashboard");
  const [buttonGoBack, setButtonGoBack] = useState(false);

  async function getReports() {
    const dom = [["id", "in", props.accReports]];
    const orderBy = [["sequence", "ASC"]];

    let groupCharts = {};
    let { data: res } = await proxy.search(
      "dash.access.report",
      dom,
      FIELDS,
      null,
      orderBy,
    );
    if (res && res.length === 0) {
      return;
    }
    res.forEach((rec) => {
      console.log("rec chart .....", rec);
      groupCharts[rec.category] = [];
    });
    res.forEach((rec) => {
      let data = { ...rec["report."] };
      delete rec["report."];
      const reportData = { ...rec, ...data };
      groupCharts[rec.category].push(reportData);
    });
    setReports(groupCharts);
  }

  useEffect(() => {
    getReports();
  }, []);

  if (!reports) {
    return null;
  }

  function handleGoBack() {
    setCategory(null);
    setTitle("dashboard");
    setView("dash");
    setButtonGoBack(false);
  }

  function onSelected(category) {
    setCategory(category);
    setTitle(category);
    setView(category);
    setButtonGoBack(true);
  }

  return (
    <div className="p-6 w-full">
      <div className="flex justify-between">
        <p className="text-5xl font-bold text-slate-600">{<FM id={title} />}</p>
        {buttonGoBack && (
          <Button color="blue" onClick={handleGoBack}>
            <ArrowSmallLeftIcon
              className="h-6 w-6 mx-auto"
              aria-hidden="true"
            />
          </Button>
        )}
      </div>
      {view === "dash" ? (
        <div className="flex flex-wrap">
          {Object.entries(reports).map((rec, index) => (
            <CardGroupCharts
              key={index}
              category={rec[0]}
              charts={rec[1]}
              onSelected={onSelected}
            />
          ))}
        </div>
      ) : (
        <GridChart accReports={reports[category]} />
      )}
    </div>
  );
}

export default DashChart;
